import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Seo from '../components/seo';

import downloadPrivacy from '../downloads/Throughlife-Privacy-Policy.pdf';
import downloadFSG from '../downloads/Throughlife-FSG.pdf';
import downloadCPD from '../downloads/Throughlife-CPD-Policy.pdf';

export default function AboutPage() {
    return (
        <Layout>
            <Seo
                title="About Us"
                description="You live life, we'll handle the paperwork"
            />
            <div className="intbanner">
                <StaticImage
                    src="../images/tl-int-banner1.jpg"
                    alt="About Us"
                    placeholder="dominant_color"
                    layout="fullWidth"
                    quality={100}
                />
            </div>
            <div className="content">
                <div className="container">
                    <div className="grid">
                        <div className="col-lg-12 col-bleed about--content">
                            <h1>
                                About Us
                                <span>
                                    You live life, we'll handle the paperwork
                                </span>
                            </h1>
                            <p>
                                Since beginning in 1986, Throughlife Financial
                                Solutions have built an enviable reputation
                                based on a solid foundation of efficiency,
                                knowledge and an absolute 'dedication to
                                service.' The team at Throughlife Financial
                                Solutions understand the importance of
                                well-informed decision-making and provide a
                                range of options and structures to assist you to
                                achieve your financial goals.
                            </p>
                            <p>
                                Steven Mateljan is the primary adviser at
                                Throughlife Financial Solutions and has been
                                manager of the business since 2006. He has
                                completed his Advanced Diploma of Financial
                                Planning and is currently studying his Graduate
                                Diploma of Financial Planning. With a focus on
                                high service standards and strategy driven
                                advice Steven has grown the Throughlife
                                Financial Solutions advice process to the
                                professional service it is today.
                            </p>
                            <p>
                                Alina Bailey is an adviser at Throughlife
                                Financial Solutions and has been a member of the
                                team since 2012. Alina has completed her
                                Graduate Diploma of Financial Planning and holds
                                a Bachelor of Social Sciences from Curtin
                                University. With over 16 years of industry
                                experience, Alina understands the importance of
                                financial security for clients and enjoys
                                helping clients articulate and achieve their
                                goals and objectives. During her time at
                                Throughlife, Alina has worked in many areas of
                                the business and helped develop the service
                                offering provided today.
                            </p>
                            <p>
                                Doug Mateljan CPA (FPS) is an Authorised
                                Representative of Throughlife Financial
                                Solutions. Doug has over 30 years of business
                                experience including senior executive, financial
                                and general management positions in large
                                corporations. Doug is available to speak with
                                clients, however, he no longer provides personal
                                financial advice.
                            </p>
                            <p>
                                <strong>
                                    Accreditations and Qualifications
                                </strong>
                            </p>
                            <ul>
                                <li>
                                    ASIC Australian Financial Services Licence
                                    No 221937
                                </li>
                                <li>CPA Australia Member</li>
                                <li>Financial Planning Association Member</li>
                                <li>
                                    Advanced Diploma for Financial Planning
                                    Accreditation
                                </li>
                                <li>
                                    Financial Services Guide -{' '}
                                    <a href={downloadFSG} download>
                                        FSG
                                    </a>
                                </li>
                                <li>
                                    Privacy Policy -{' '}
                                    <a href={downloadPrivacy} download>
                                        Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    Continuous Professional Development -{' '}
                                    <a href={downloadCPD} download>
                                        CPD Policy
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
